import React, { useEffect } from "react";
import "./ShowVoucher.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Header } from "antd/es/layout/layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";


// import { Button } from "@/src/components/ui/button";


const ShowVoucher: React.FC = () => {


    const location = useLocation();
    const navigate = useNavigate();

    const lang = localStorage.getItem('language')

    const { firstname, lastname, code, duration, create_time, quota } = location.state || {};
    console.log('voucher' + code, firstname, lastname, duration, create_time);

    const voucherModified = code.substr(0, (code.length) / 2) + '-' + code.substr((code.length) / 2, code.length)


    const voucherTime = new Date(create_time * 1000);
    console.log('vouchertime ', voucherTime);


    // 
    const requestTimeToLocal = new Date(voucherTime.getTime() - voucherTime.getTimezoneOffset() * 60 * 1000);
    // 




    const voucherDuration = duration;
    const addDays = 60 * 60000 * 24 * (voucherDuration / 1440);
    const voucherExpire = new Date((voucherTime.getTime()) + addDays)

    const voucherLeft = ((voucherExpire.getTime()) - Date.now()) / 1000 / 60 / 60

    console.log('voucherLeft' + getTimeLeft(voucherExpire));

    console.log(voucherExpire.getTime());
    console.log(Date.now());


    console.log(voucherLeft);


    const year = voucherExpire.getFullYear();
    const month = (voucherExpire.getMonth() < 10 ? '0' : '') + (voucherExpire.getMonth() + 1);
    const day = (voucherExpire.getDate() < 10 ? '0' : '') + voucherExpire.getDate();
    const hoursInter = (voucherExpire.getHours() < 10 ? '0' : '') + voucherExpire.getHours();
    const hours = lang === 'de' ? hoursInter : parseInt(hoursInter) > 12 ? parseInt(hoursInter) - 12 : hoursInter;
    const minutes = (voucherExpire.getMinutes() < 10 ? '0' : '') + voucherExpire.getMinutes();
    // const seconds = (voucherExpire.getSeconds() < 10 ? '0' : '') + voucherExpire.getSeconds();




    function getTimeLeft(expire: Date) {
        let timeLeft = ((expire.getTime()) - Date.now()) / 1000 / 60 / 60
        if (timeLeft > 48) {
            timeLeft = Math.round((timeLeft / 24) - 0.5)
            return ` ${timeLeft} Tage`
        } if (timeLeft < 48 && timeLeft > 24) {
            timeLeft = Math.round((timeLeft / 24) - 0.5)
            return ` ${timeLeft} Tag`
        } else {
            timeLeft = Math.round(timeLeft - 0.5)
            return `${timeLeft}`
        }
    }

    function getTimeZ(day: string, month: string, year: number) {
        if (localStorage.getItem('language') === 'de') {
            return `${day}.${month}.${year}`
        }
        else {
            return `${month}/${day}/${year}`
        }
    }


    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'de';
        i18n.changeLanguage(storedLanguage);
    }, []);

    const nextSteps = async () => {
        navigate("/instructions")
    }


    return (
        <div className="uiCard" >
            <Header className="voucher__header">
                <h2 className="voucher__headline">{t('defHeadline')}</h2>

                <p className="dataTitle"> {t('vouHeadline')} {firstname} {lastname}</p>
            </Header>
            <div className="bigData">
                <div className="voucherData">
                    <label className="label">SSID: </label> </div>
                <div className="voucherData"> <span className="data"> TIMETOACT Guest</span> </div>

                <div className="voucherData">
                    <label className="label">{t('password')} </label> </div>
                <div className="voucherData"> <span className="data"> {voucherModified} </span> </div>

                <div className="voucherData">
                    <label className="label">{t('vouDuration')} </label></div>
                <div className="voucherData"> <span className="data"> {getTimeZ(day, month, year)} {hours}:{minutes} {lang === 'de' ? '' : parseInt(hoursInter) > 12 ? 'PM' : 'AM'} <br /> &#040;{t('vouTimepr')}{getTimeLeft(voucherExpire)}{t('vouTimepo')}&#041;</span></div>
            </div>

            <img className="qrcode" src='assets/TIMETOACT Guest-qrcode.png' alt="Wikipedia QR-Code" />
            <p className="ssid">TIMETOACT Guest</p>
            <button className="nextSteps" onClick={nextSteps}>{t('nextSteps')} <FontAwesomeIcon icon={faQuestionCircle} /></button>
        </div>
    )
}

export default ShowVoucher;