import React, { useCallback } from "react";
import i18next from 'i18next';
import { initReactI18next, useTranslation, withTranslation } from 'react-i18next';
import "./Home.css";

import '../../i18n'
import { useLanguage, LanguageProvider } from '../../contexts/LanguageContext';
import { useNavigate } from "react-router-dom";
import { Button, Flex, Segmented, Space } from "antd";
import { Link } from "react-router-dom";


// import { Button } from "@/src/components/ui/button";


const Home: React.FC = () => {

    const { language, changeLanguage } = useLanguage();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    console.log(language, t('defaultButton'));




    // const changeLangDE = () => {
    //     i18n.changeLanguage('de');
    //     // setLanguage('de');
    // }

    // const changeLangEN = async () => {
    //     i18n.changeLanguage('en');
    //     // setLanguage('en');

    // }


    const create24hVoucher = async () => {
        navigate("/defaultVoucher")
    }

    const createCustomVoucher = async () => {
        navigate("/customVoucher")
    }


    return (
        <Flex vertical gap="large" align="center" className="uiCard" >
            <img className="logo" src='assets/TAT-Logo.png' alt='LOGO' />

            <div className="home__headline">
                <h1 >Voucher Generator</h1>
            </div>

            <Flex wrap gap="large" justify="center">
                <Link to="/defaultVoucher">
                    <Button size="large" type="primary">{t('defaultButton')}</Button>
                </Link>

                <Link to="/customVoucher">
                    <Button size="large" disabled >{t('customButton')}</Button>
                </Link>

            </Flex>


            <div className="footer">
                <span className="footer__start">&copy; 2024</span>
            <Segmented options={['de', 'en']} value={language} onChange={changeLanguage} />
                <span className="footer__end">Admin Panel</span>
            </div>
        </Flex>
    )

}

export default withTranslation()(Home);
