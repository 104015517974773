import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


const de = {
    // Home
    defaultButton: '24h Direkter Zugang ',
    customButton: 'Benutzerdefinierter Zugang',
    // DefaultVoucher
    defHeadline: 'Direkter Zugang',
    fname: 'Vorname',
    lname: 'Nachname',
    contactP: 'Kontaktperson',
    defInstructions: 'Ihre E-Mail wird zum Zusenden des Access-Codes benötigt!',
    defButton: 'Code anfragen',
    // CustomVoucher
    cusHeadline: 'Benutzerdefinierter Zugang',
    cusDays: 'Anzahl Tage',
    cusDevices: 'Anzahl Geräte',
    cusReason: 'Grund',
    alert: 'Sie haben erfolgreich einen Voucher für das Wifi angefagt. Drücken Sie auf OK um eine weitere Anfrage zu stellen.',
    // ShowVoucher
    vouHeadline: 'Zugang für ',
    password: 'Passwort:',
    vouDuration: 'Gültig bis:',
    vouTimepr: 'noch ',
    vouTimepo: ' Stunden',
    nextSteps: 'Nächste Schritte',
    // Instructions
    insHeadline: 'WLAN-Verbindung',
    mobile1: 'Nach dem Scannen des QR-Codes öffnen sich die Wifi-Einstellungen.',
    mobile2: 'Alternativ können die Wifi-Einstellungen manuell aufgerufen werden.',
    mobile3: 'Nach dem Auswählen des Wifi mit der SSID "TIMETOACT Guest" öffnet sich eine neue Webseite.',
    mobile4: 'Hier muss der 10-stellige Code eingegeben werden. Danach besteht eine Internetverbindung für die zuvor definierte Zeit.',
    mobile5: 'Nachdem der Code eingegeben wurde, muss das Zertifikat mit "Fortfahren" bestätigt werden.',
    pc1: 'Nachdem ein Code generiert wurde, muss das Wifi mit der SSID "TIMETOACT Guest" ausgewählt werden.',
    pc2: 'Nach dem Auswählen des Wifi mit der SSID "TIMETOACT Guest" öffnet sich eine neue Webseite.',
    pc3: 'Hier muss der 10-stellige Code eingegeben werden. Danach besteht eine Internetverbindung für die zuvor definierte Zeit.',

}

const en = {
    // Home
    defaultButton: '24h Direct Access',
    customButton: 'Custom Access',
    // DefaultVoucher
    defHeadline: 'Direct Access',
    fname: 'Firstname',
    lname: 'Lastname',
    contactP: 'Contact-Person',
    defInstructions: 'Your email is required to send the access code!',
    defButton: 'Request Code',
    // CustomVoucher
    cusHeadline: 'Custom Access',
    cusDays: 'Number of Days',
    cusDevices: 'Number of Devices',
    cusReason: 'Reason',
    alert: 'You have successfully requested a voucher for the Wi-Fi. Press OK to submit another request.',
    // ShowVoucher
    vouHeadline: 'Access for ',
    password: 'Password:',
    vouDuration: 'Valid until:',
    vouTimepr: '',
    vouTimepo: 'h left',
    nextSteps: 'Next Steps',
    // Instructions
    insHeadline: 'Wi-Fi Connection',
    mobile1: 'After scanning the QR code, the Wi-Fi settings will open.',
    mobile2: 'Alternatively, the Wi-Fi settings can be accessed manually.',
    mobile3: 'After selecting the Wi-Fi with the SSID "TIMETOACT Guest", a new web page will open.',
    mobile4: 'Here, the 10-digit code must be entered. After that, there will be internet access for the previously defined time.',
    mobile5: 'After entering the code, the certificate must be confirmed with "Fortfahren".',
    pc1: 'After a code has been generated, the Wi-Fi with the SSID "TIMETOACT Guest" must be selected.',
    pc2: 'After selecting the Wi-Fi with the SSID "TIMETOACT Guest", a new web page will open.',
    pc3: 'Here, the 10-digit code must be entered. After that, there will be internet access for the previously defined time.'

}

i18n
    .use(initReactI18next)
    .init({
        lng: 'de',
        fallbackLng: 'de',
        resources: {
            en: {
                translation: en,
            },
            de: {
                translation: de,
            },
        },
        debug: true,

    });

export default i18n;