import React, { useEffect } from "react";
import axios from 'axios';
import { useState } from 'react';
import "./CustomVoucher.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { Alert, Button, Flex, Input, Modal, Radio, Space } from 'antd';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import TextArea from "antd/es/input/TextArea";
import { Header } from "antd/es/layout/layout";

const CustomVoucher: React.FC = () => {
    const [firstname, setFirstname] = useState("");
    const [firstnameTouched, setFirstnameTouched] = useState(false);
    const [lastname, setLastname] = useState("");
    const [lastnameTouched, setLastnameTouched] = useState(false);
    const [email, setEmail] = useState("");
    const [emailTouched, setEmailTouched] = useState(false);
    const [contactP, setContactP] = useState("");
    const [contactPtouched, setContactPtouched] = useState(false);
    const [days, setDays] = useState("");
    const [reason, setReason] = useState("");
    const [multiUse, setMultiUse] = useState(false);
    const [devices, setDevices] = useState("");


    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const location = useLocation()

    const success = () => {
        Modal.success({
            content: t('alert'),
            centered: true
        });

        navigate("/");
    };

    const AzureUrl = process.env.REACT_APP_AZURE_FUNCTIONS_URL;
    const recipient = email;
    // dotenv.config();
    const navigate = useNavigate();

    // const singleUseRadio = () => {
    //     setMultiUse(false);
    //     setDevices("1");
    //     console.log(multiUse);
    //     console.log(devices);
    // }


    // const multiUseRadio = () => {
    //     setMultiUse(true);
    //     console.log(multiUse);
    // }

    const onChangeRadio = (e: any) => {
        console.log('radio checked', e.target.value);
        setMultiUse(e.target.value);
    }

    const { t, i18n } = useTranslation();

    // const [errors, setErrors] = useState([]);
    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'de';
        i18n.changeLanguage(storedLanguage);
    }, []);


    useEffect(() => {
        const isValid =
            firstname.trim().length > 1 &&
            lastname.trim().length > 1 &&
            emailRegex.test(email.trim()) &&
            contactP.trim().length > 1 &&
            parseInt(days) >= 1 && parseInt(days) <= 365 &&
            parseInt(devices) >= 1 && parseInt(devices) <= 9 &&
            reason.trim().length > 2 &&
            (
                (parseInt(days) === 1 && parseInt(devices) > 1) ||
                (parseInt(devices) === 1 && parseInt(days) > 1) ||
                (parseInt(devices) > 1 && parseInt(days) > 1)
            ) &&
            (
                (multiUse === false) ||
                (multiUse === true && parseInt(devices) > 1 && parseInt(devices) < 10)
            )

        setIsFormValid(isValid);
    }, [firstname, lastname, email, contactP, days, reason, multiUse, devices]);


    useEffect(() => {
        const multiUseTrue = multiUse;
        setMultiUse(multiUseTrue);
        setDevices("1")
    }, [multiUse]);

    // const requestVoucher = async () => {
    //     console.log('API request - POST');
    //     const voucher = await axios
    //         .post('/api/createVoucherTest1', data);
    //     console.log('after Axios');

    //     console.log('Voucher:');
    //     console.log(voucher);

    //     (voucher);

    //     const voucherProps = { firstname, lastname, ...voucher.data };
    //     navigate("/showVoucher", { state: voucherProps });
    // }

    const requestVoucher = async () => {
        console.log('API request - POST');
        setLoading(true);

        try {
            const res = await axios.post('api/requestEmail', { firstname, lastname, email, contactP, days, reason, devices })
            console.log('after Axios request Email');
            console.log(res);
            console.log(typeof (res.data.note));



            await axios.post('api/saveData', { firstname, lastname, email, contactP, days, reason, devices, res })

            setIsFormValid(false);
            success();
            // navigate("/");
        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false);

        }
    }



    const requestMail = async (voucher: object) => {
        console.log('API request - requestMail');
        await axios
            .post(AzureUrl + 'api/requestEmail', { recipient, voucher })
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
        console.log('after sendMail');
    }

    const emailRegex = /^\S+@\S+\.\S+$/;

    const headerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        // textAlign: 'left',
        color: '#215ea9',
        height: 'fit-content',
        paddingInline: 10,
        lineHeight: '30px',
        backgroundColor: '#ffffff',
        fontWeight: 'bold',
        paddingBottom: 10,

    };



    return (
        <div className="uiCard">
            <Header style={headerStyle}>
                <div className="default__header-left">
                    <Link to="/"><Button size="large" icon={<FontAwesomeIcon icon={faArrowLeft} />} /></Link>
                </div>
                
                <div className="default__header-center">
                    <h2>{t('cusHeadline')}</h2>
                </div>
            </Header>


            <Flex vertical gap="middle">

                <form>
                    <div className="formgroup">
                        <label htmlFor="firstname">
                            {t('fname')}<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="firstname"
                            id="firstname"
                            // type='text' 
                            value={firstname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setFirstname(e.target.value)}
                            onBlur={() => setFirstnameTouched(true)}
                            className='login'
                        /> */}
                        <Input
                            className="custom_input"
                            name="firstname"
                            id="firstname"
                            value={firstname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setFirstname(e.target.value)}
                            onBlur={() => setFirstnameTouched(true)}
                        />
                    </div>
                    <div className="formgroup">
                        <label htmlFor="lastname">
                            {t('lname')}<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="lastname"
                            id="lastname"
                            // type='text' 
                            value={lastname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setLastname(e.target.value)}
                            onBlur={() => setLastnameTouched(true)}
                        /> */}
                        <Input
                            className="custom_input"
                            name="lastname"
                            id="lastname"
                            value={lastname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setLastname(e.target.value)}
                            onBlur={() => setLastnameTouched(true)}
                        />
                    </div>
                    <div className="formgroup">
                        <label htmlFor="email" >
                            E-Mail<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="email"
                            id="email"
                            // type='email' 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => setEmailTouched(true)}
                        /> */}
                        <Input
                            className="custom_input"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => setEmailTouched(true)}
                        />
                    </div>
                    {/* ------------------------------------------------------------------------- */}
                    <div className="formgroup">
                        <label htmlFor="contactP">
                            {t('contactP')}<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="contactP"
                            id="contactP"
                            // type='text' 
                            value={contactP}
                            minLength={2}
                            maxLength={40}
                            onChange={(e) => setContactP(e.target.value)}
                            onBlur={() => setContactPtouched(true)}
                        /> */}
                        <Input
                            className="custom_input"
                            name="contactP"
                            id="contactP"
                            // type='text' 
                            value={contactP}
                            minLength={2}
                            maxLength={40}
                            onChange={(e) => setContactP(e.target.value)}
                            onBlur={() => setContactPtouched(true)}
                        />
                    </div>
                    {/* ------------------------------------------------------------------------- */}
                    <div className="formgroup">
                        <label htmlFor="days" >
                            {t('cusDays')}
                        </label>
                        {/* <input
                            name="days"
                            id="days"
                            type="number"
                            value={days}
                            onChange={(e) => setDays(e.target.value)}
                        /> */}
                        <Input
                            className="custom_input"
                            name="days"
                            id="days"
                            type='number'
                            value={days}
                            minLength={2}
                            maxLength={40}
                            onChange={(e) => setDays(e.target.value)}
                            onBlur={() => setContactPtouched(true)}
                        />
                    </div>
                    {/* ------------------------------------------------------------------------- */}


                    <div className="radiobuttons">
                        {/* <input
                            type="radio"
                            name="duration"
                            id="singleUse"
                            value="singleUse"
                            onChange={singleUseRadio}
                        />
                        <label className="radio" htmlFor="singleUse">Single-use</label>

                        <input
                            type="radio"
                            name="duration"
                            id="multiUse"
                            onChange={multiUseRadio}
                        /> */}
                        {/* <label className="radio" htmlFor="multiUse">Multi-use</label> */}

                        <Radio.Group onChange={onChangeRadio} value={multiUse}>
                            <Radio value={false}> Single-use</Radio>
                            <Radio value={true}> Multi-use</Radio>
                        </Radio.Group>

                    </div>
                    {/* ------------------------------------------------------------------------- */}

                    {multiUse === true &&
                        <div className="formgroup">
                            <label htmlFor="devices" >
                                {t('cusDevices')}
                            </label>
                            {/* <input
                                name="devices"
                                id="devices"
                                type="number"
                                value={devices}
                                onChange={(e) => setDevices(e.target.value)}
                                disabled={!multiUse}
                            /> */}
                            <Input
                                className="custom_input"
                                name="devices"
                                id="devices"
                                type="number"
                                value={devices}
                                onChange={(e) => setDevices(e.target.value)}
                                disabled={!multiUse} />
                        </div>
                    }
                    {/* ------------------------------------------------------------------------- */}

                    <div className="formgroup">
                        <label htmlFor="reason">{t('cusReason')}<span className="mandatoryInput">*</span></label>
                        {/* <textarea
                            name="reason"
                            id="reason"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        /> */}

                        <Input.TextArea
                            className="custom_input"
                            name="reason"
                            id="reason"
                            value={reason}
                            rows={5}
                            onChange={(e) => setReason(e.target.value)} />
                    </div>

                </form>

                <Alert type="info" message={t('defInstructions')} showIcon />

            </Flex>
            <br />
            {/* <p>Your name is: {firstname}</p>
                        <p>Your name is: {lastname}</p>
                        <p>Your name is: {email}</p> */}
            {!loading ? (
                <Button className="requestButton" size="large" disabled={!isFormValid} onClick={requestVoucher} type="primary">
                    {t('defButton')} <FontAwesomeIcon icon={faArrowRight} />
                </Button>
            ) : (
                <ScaleLoader
                    color="#215ea9"
                    width={8} />
            )}


            {/* <button type="button" onClick={getVoucher} >
                            send Mail
                        </button> */}

            {/* <button type="button" onClick={showVoucher} >
                        show Voucher
                    </button> */}
        </div>
    )
}

export default CustomVoucher;


