import axios from "axios";
import { use } from "i18next";
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom'
import { Column, useSortBy, useTable } from 'react-table';
import "./AdminPanel.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RequestedVoucherTable from "./RequestedVoucherTable";
import PendingVoucherTable from "./PendingVoucherTable";
// import { Button } from "@/src/components/ui/button";


const AdminPanel: React.FC = () => {


    // const [data, setData] = useState([]);

    // console.log(data);

    // useEffect(() => {
    //     (async () => {
    //         const res = await getData();
    //         setData(res)
    //     })();
    // }, []);

    return (
        <div className="uiCard uiCard--full-size">
            <Tabs>
                <TabList>
                    <Tab>Generierte Voucher</Tab>
                    <Tab>Angefragte Voucher</Tab>
                </TabList>
                <TabPanel>
                    <RequestedVoucherTable></RequestedVoucherTable>
                </TabPanel>
                <TabPanel>
                    <PendingVoucherTable></PendingVoucherTable>
                </TabPanel>
            </Tabs>
        </div>
    );
}


// const getData = async () => {
//     const result = await axios("api/getData");
//     console.log('resuöt::: ', result.data);

//     return result.data;
// }
export default AdminPanel;


