import React, { createContext, useState, useContext, useEffect, ReactNode, FC  } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import './LanguageContext'


interface LanguageContextProps {
    language: string;
    changeLanguage: (language: string) => void;
}

interface LanguageProviderProps {
    children: ReactNode;
}

export type language = 'de' | 'en';
const defaultLang = 'de'

const LanguageContext = createContext<LanguageContextProps>({
    language: defaultLang,
    changeLanguage: () => {}
});

export const LanguageProvider = ({ children }: LanguageProviderProps): JSX.Element => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState<string>(defaultLang);

    const changeLanguage = (lang: string) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        const savedLang = localStorage.getItem('language');
            if (savedLang) {
                setLanguage(savedLang);
            }
    }, [])

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
    
};

export const useLanguage = (): LanguageContextProps => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('no Lang')
    }
    return context;
}
