import React, { useEffect } from "react";
import "./Instructions.css";
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const Instructions: React.FC = () => {


    Fancybox.bind('[data-fancybox="android"]', {

    });

    Fancybox.bind('[data-fancybox="ios"]', {
        //
    });

    Fancybox.bind('[data-fancybox="win"]', {
        //
    });

    Fancybox.bind('[data-fancybox="linux"]', {
        //
    });

    Fancybox.bind('[data-fancybox="macos"]', {
        //
    });

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();


    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'de';
        i18n.changeLanguage(storedLanguage);
    }, []);

    const headerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        // textAlign: 'left',
        color: '#215ea9',
        height: 'fit-content',
        paddingInline: 10,
        // lineHeight: '64px',
        backgroundColor: '#ffffff',
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        rowGap: 3
    };

    return (
        <div className="uiCard" >
                 <Header className="header" style={headerStyle}>
                <div className="default__header-left">
                    <Link to="/"><Button size="large" icon={<FontAwesomeIcon icon={faArrowLeft} />} /></Link>
                </div>
                <div className="default__header-center">
                    <h2>{t('insHeadline')}</h2>
                </div>
            </Header>
            <div className="accordion">
                <details className="first">
                    <summary>
                        <span className="accordionPanel">Android</span>
                    </summary>

                    <div className="content">
                        <p className="instructions">
                            {t('mobile1')} <br />
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\android\AndroidSettings.jpg" data-fancybox="android">
                                <img src="assets\instructionsCropped\android\AndroidSettings.jpg" alt="Screenshot of Android-Wifi-Settings" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('mobile2')}
                            <br />
                            <br />
                            {t('mobile3')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\android\insertCode.jpg" data-fancybox="android">
                                <img src="assets\instructionsCropped\android\insertCode.jpg" alt="Screenshot of Code Popup" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('mobile4')}
                        </p>
                    </div>
                </details>

                <details>
                    <summary>
                        <span className="accordionPanel">iOS</span>
                    </summary>
                    <div className="content">
                        <p className="instructions">
                            {t('mobile1')} <br />
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\iOS\iosSettings.jpg" data-fancybox="ios">
                                <img src="assets\instructionsCropped\iOS\iosSettings.jpg" alt="Screenshot of iOS-Wifi-Settings" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('mobile2')}
                            <br />
                            <br />
                            {t('mobile3')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\iOS\inputCode.jpg" data-fancybox="ios">
                                <img src="assets\instructionsCropped\iOS\inputCode.jpg" alt="Screenshot of Code Popup" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('mobile4')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\iOS\checkCert.jpg" data-fancybox="ios">
                                <img src="assets\instructionsCropped\iOS\checkCert.jpg" alt="Screenshot of Code Popup" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('mobile5')}
                        </p>
                    </div>
                </details>

                <details>
                    <summary>
                        <span className="accordionPanel">Windows</span>
                    </summary>
                    <div className="content">
                        <p className="instructions">
                            {t('pc1')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\windows\WindowsSettings.png" data-fancybox="win">
                                <img src="assets\instructionsCropped\windows\WindowsSettings.png" alt="Screenshot of Windows-Wifi-Settings" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('pc2')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\windows\insertCode.png" data-fancybox="win">
                                <img src="assets\instructionsCropped\windows\insertCode.png" alt="Screenshot of Code Popup" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('pc3')}
                        </p>

                    </div>
                </details>

                <details>
                    <summary>
                        <span className="accordionPanel">Linux</span>
                    </summary>

                    <div className="content">
                        <p className="instructions">
                            {t('pc1')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\linux\Linuxsettings.png" data-fancybox="linux">
                                <img src="assets\instructionsCropped\linux\LinuxSettings.png" alt="Screenshot of Linux-Wifi-Settings" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('pc2')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\linux\insertCode.png" data-fancybox="linux">
                                <img src="assets\instructionsCropped\linux\insertCode.png" alt="Screenshot of Code Popup" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('pc3')}
                        </p>
                    </div>
                </details>

                <details>
                    <summary>
                        <span className="accordionPanel">MacOS</span>
                    </summary>

                    <div className="content">
                        <p className="instructions">
                            {t('pc1')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\macOS\macosSettings.jpg" data-fancybox="macos">
                                <img src="assets\instructionsCropped\macOS\macosSettings.jpg" alt="Screenshot of macOS-Wifi-Settings" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('pc2')}
                        </p>
                        <section className="fancybox">
                            <a href="assets\instructions\macOS\insertCode.jpg" data-fancybox="macos">
                                <img src="assets\instructionsCropped\macOS\insertCode.png" alt="Screenshot of Code Popup" />
                            </a>
                        </section>
                        <p className="instructions">
                            {t('pc3')}
                        </p>
                    </div>
                </details>
            </div>
        </div>
    )
}

export default Instructions;