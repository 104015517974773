import React, { useEffect, useRef } from "react";
import axios from 'axios';
import { useState } from 'react';
import "./DefaultVoucher.css"
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, withTranslation } from "react-i18next";

import { useLanguage, LanguageProvider } from '../../contexts/LanguageContext';
import i18next from "i18next";

import { ScaleLoader } from 'react-spinners'
import { Alert, Button, Flex, Input, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faPersonWalkingDashedLineArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Header } from "antd/es/layout/layout";

const DefaultVoucher: React.FC = () => {
    const [firstname, setFirstname] = useState("");
    const [firstnameTouched, setFirstnameTouched] = useState(false);
    const [lastname, setLastname] = useState("");
    const [lastnameTouched, setLastnameTouched] = useState(false);
    const [email, setEmail] = useState("");
    const [emailTouched, setEmailTouched] = useState(false);
    const [contactP, setContactP] = useState("");
    const [contactPtouched, setContactPtouched] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);


    const [code, setCode] = useState("");

    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);



    const navigate = useNavigate();
    // const language = i18next.resolvedLanguage

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language') || 'de';
        i18n.changeLanguage(storedLanguage);
    }, []);


    const { t, i18n } = useTranslation();
    const { language } = useLanguage();

    // SET FPR LOCAL
    const AzureUrl = process.env.REACT_APP_AZURE_FUNCTIONS_URL;

    const to = email


    // const [errors, setErrors] = useState([]);

    useEffect(() => {
        const isValid =
            firstname.trim().length > 1 &&
            lastname.trim().length > 1 &&
            emailRegex.test(email.trim()) &&
            clicked === false &&
            contactP.trim().length > 1;
        setIsFormValid(isValid);
    }, [firstname, lastname, email, clicked, contactP]);


    const back = () => {
        window.location.replace("/")
    }




    const getVoucher = async () => {
        console.log('API request - POST');

        setLoading(true);
        try {
            const voucherCreated = await createVoucher(data.days, data.devices);
            console.log('CreateVoucher:' + (JSON.stringify(voucherCreated)));

            const note = voucherCreated.note
            console.log(note);
            console.log(typeof (note));



            const voucherRetrieved = await retrieveVoucher(voucherCreated, note);
            console.log('RetrieveVoucher:' + (JSON.stringify(voucherRetrieved)));


            const note2 = voucherRetrieved.note
            console.log(typeof (note2));


            // console.log('GETvoucher:' + (getVoucher) ) ;

            // console.log(getVoucher);

            // console.log('after Axios');

            // console.log('response: ' + getVoucher);

            // console.log('Voucher:');
            // console.log(getVoucher);

            setCode(voucherRetrieved.code)
            console.log('CODE: ' + code);

            const saveToDB = await axios
                .post(`api/saveData`, { voucherRetrieved, firstname, lastname, email, contactP })

            // if (saveToDB) {
            //     console.log('Data saved successfully')
            // } else {
            //     console.log('Cant save Data')
            // }


            sendMail(to, voucherRetrieved, language);

            const voucherProps = { firstname, lastname, ...voucherRetrieved };
            navigate("/showVoucher", { state: voucherProps });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }


    const createVoucher = async (days: number, devices: number, note?: number) => {
        console.log('createVoucher');
        for (let retry = 0; retry < 5; retry++) {
            console.log('retry create: ' + (retry + 1));
            try {
                const voucher = await axios
                    .post(`api/createVoucher`, { days, devices, note });

                console.log(voucher.data);
                return voucher.data;

            }
            catch {
                console.error(`Fehler: ${Error}. Versuch ${retry + 1} von ${5}`);
            }
        }

    }

    const retrieveVoucher = async (createdVoucher: any, note: number) => {
        console.log('getVoucher');
        for (let retry = 0; retry < 5; retry++) {
            console.log('retry get: ' + (retry + 1));
            try {
                const voucher = await axios
                    .post(`api/createVoucherTest1`, { createdVoucher, note });

                console.log(voucher.data);
                return voucher.data;
            }
            catch {
                console.error(`Fehler: ${Error}. Versuch ${retry + 1} von ${5}`);
            }
        }
    }


    const sendMail = async (recipient: string, voucher: any, language: string) => {
        console.log('API request - sendMail');
        await axios
            .post(`api/sendEmail`, { recipient, voucher, language })
        // .then(res => {
        //     console.log(res);
        //     console.log(res.data);
        // })
        console.log('after sendMail');
    }



    const emailRegex = /^\S+@\S+\.\S+$/;

    const data = {
        'days': 1,
        'devices': 1
    }


    const headerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        // textAlign: 'left',
        color: '#215ea9',
        height: 'fit-content',
        paddingInline: 10,
        lineHeight: '30px',
        backgroundColor: '#ffffff',
        fontWeight: 'bold',
    }

    // display: flex;
    // justify-content: center;
    // font-size: 0.7em;
    // color: #215ea9;
    // font-weight: bold;
    // margin: 1rem 0.3rem;

    return (
        <div className="uiCard" >
            <Header style={headerStyle}>
                <div className="default__header-left">
                    <Link to="/"><Button size="large" icon={<FontAwesomeIcon icon={faArrowLeft} />} /></Link>
                </div>
                <div className="default__header-center">
                    <h2>{t('defHeadline')}</h2>
                </div>
            </Header>


            <Flex vertical gap="middle">
                <form>


                    <div className="formgroup">
                        <label htmlFor="firstname">
                            {t('fname')}<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="firstname"
                            id="firstname"
                            // type='text' 
                            value={firstname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setFirstname(e.target.value)}
                            onBlur={() => setFirstnameTouched(true)}
                            className='login'
                        /> */}
                        <Input
                            className="custom_input"
                            name="firstname"
                            id="firstname"
                            value={firstname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setFirstname(e.target.value)}
                            onBlur={() => setFirstnameTouched(true)}
                        />
                    </div>

                    <div className="formgroup">
                        <label htmlFor="lastname">
                            {t('lname')}<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="lastname"
                            id="lastname"
                            // type='text' 
                            value={lastname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setLastname(e.target.value)}
                            onBlur={() => setLastnameTouched(true)}
                        /> */}
                        <Input
                            className="custom_input"
                            name="lastname"
                            id="lastname"
                            value={lastname}
                            minLength={2}
                            maxLength={20}
                            onChange={(e) => setLastname(e.target.value)}
                            onBlur={() => setLastnameTouched(true)}
                        />
                    </div>

                    <div className="formgroup">
                        <label htmlFor="email" >
                            E-Mail<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="email"
                            id="email"
                            // type='email' 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => setEmailTouched(true)}
                        /> */}
                        <Input
                            className="custom_input"
                            name="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={() => setEmailTouched(true)}
                        />

                    </div>

                    <div className="formgroup">
                        <label htmlFor="contactP">
                            {t('contactP')}<span className="mandatoryInput">*</span>
                        </label>
                        {/* <input
                            name="contactP"
                            id="contactP"
                            // type='text' 
                            value={contactP}
                            minLength={2}
                            maxLength={40}
                            onChange={(e) => setContactP(e.target.value)}
                            onBlur={() => setContactPtouched(true)}
                        /> */}
                        <Input
                            className="custom_input"
                            name="contactP"
                            id="contactP"
                            // type='text' 
                            value={contactP}
                            minLength={2}
                            maxLength={40}
                            onChange={(e) => setContactP(e.target.value)}
                            onBlur={() => setContactPtouched(true)}
                        />
                    </div>
                </form>

                <Alert className="voucher__alert" type="info" message={t('defInstructions')} showIcon />
            </Flex>
            <br />
            {
                !loading ? (
                    <Button className="requestButton" size="large" disabled={!isFormValid} onClick={getVoucher} type="primary">
                        {t('defButton')} <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                ) : (
                    <ScaleLoader
                        color="#215ea9"
                        width={8} />
                )
            }

            {/*                         
                        <button type="button" onClick={sendMail} >
                            send Mail
                        </button> */}

            {/* <button type="button" onClick={showVoucher} >
                        show Voucher
                    </button> */}
        </div >
    )
}

export default withTranslation()(DefaultVoucher);


