import React from 'react';
import { render, } from "react-dom";
import { createRoot } from 'react-dom/client';


import './output.css';
import App from './App';
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';
import './i18n'



render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

