import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AdminPanel from "./AdminPanel";
import { Button, Modal, Input, Table, type TableColumnType, TableColumnsType } from 'antd';
import { ColumnType } from "antd/es/table";


const RequestedVocherTable: React.FC = () => {
    const [data, setData] = useState([]);




    type Data = {
        Voucher_ID: number,
        GUID: string,
        Firstname: string,
        Lastname: string,
        Email: string,
        ContactPerson: string,
        Password: string,
        RequestTime: Date,
        Note: number,
        Custom_Voucher: boolean,
        Duration: number,
        Multiuse: boolean,
        Devices: number,
        Reason: string,
        State: string,
    }

    const columns: TableColumnsType<Data> = useMemo(
        () => [
            {
                title: 'Note', dataIndex: 'Note', sorter: (a, b) => a.Note - b.Note,
            },
            {
                title: 'Angefragt um', dataIndex: 'RequestTime',
            },
            {
                title: 'Vorname', dataIndex: 'Firstname',
            },
            {
                title: 'Nachname', dataIndex: 'Lastname',
            },
            {
                title: 'E-Mail', dataIndex: 'Email',
            },
            {
                title: 'Kontaktperson', dataIndex: 'ContactPerson',
            },
            {
                title: 'Passwort', dataIndex: 'Password',
            },
            {
                title: 'Custom Voucher', dataIndex: 'Custom_Voucher',
            },
            {
                title: 'Gültigkeitsdauer', dataIndex: 'Duration',
            },
            {
                title: 'Mehrfachnutzung', dataIndex: 'Multiuse',
            },
            {
                title: 'Anzahl Geräte', dataIndex: 'Devices',
            },
            {
                title: 'Begründung', dataIndex: 'Reason',
            },
            {
                title: 'Status', dataIndex: 'State',
                render: (value: Data['State'], record, index) => (
                    <td style={{
                        padding: '0.5rem',
                        border: '0',
                        borderRadius: '10px',
                        marginTop: '0.1rem',
                        backgroundColor: value === 'approved' ? '#4CAF50' : value === 'declined' ? '#E57373' : '#FFEB3B'
                    }}>{value}</td>
                ),
            },
        ]satisfies TableColumnsType<Data>,
        []
    );


    useEffect(() => {
        (async () => {
            const res = await getData();
            setData(res)
        })();
    }, []);


    return <Table<Data> columns={columns} dataSource={data} />

    /* return (
        <>
            <table {...getTableProps}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? column.isSortedDesc ? " 🔽" : " 🔼" : " ◀️"}
                                    </span>
                                </th>

                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    )
                                })}
                            </tr>
                        )
                    })}

                </tbody>
            </table>

        </>
    ); */
}


const getData = async () => {
    const result = await axios("api/getData");
    console.log('resuöt::: ', result.data);

    return result.data;
}
export default RequestedVocherTable;
