import './index.css';

import React, { createContext, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import TestApiRequest from './components/TestApiRequest';
import Home from './components/Home/Home';
import DefaultVoucher from './components/DefaultVoucher/DefaultVoucher';
import CreateCustomVoucher from './components/CustomVoucher/CustomVoucher';
import ShowVoucher from './components/ShowVoucher/ShowVoucher';
import Instructions from './components/Instructions/Instructions';

import VoucherRequest from './components/VoucherRequest';
import AdminPanel from './components/AdminPanel/AdminPanel';
import './i18n';

import { LanguageProvider, language, useLanguage } from './contexts/LanguageContext';
import { I18nContext } from 'react-i18next';
import { ConfigProvider } from 'antd';


function App() {
  // const { language, i18n, changeLanguage } = useContext(I18nContext);
  // const toggleLanguage = () => {
  //   changeLanguage(language === 'en' ? 'fr' : 'en');
  // };


  return (
    <ConfigProvider
      theme={{
        token: { colorPrimary: '#375ea7' },
      }}
    >
      <LanguageProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home />
              }
            />
            <Route
              path="/VoucherRequest"
              element={
                <VoucherRequest />
              }
            />
            <Route
              path="/defaultVoucher"
              element={
                <DefaultVoucher />
              }
            />
            <Route
              path="/customVoucher"
              element={
                <CreateCustomVoucher />
              }
            />
            <Route
              path="/showVoucher"
              element={
                <ShowVoucher />
              }
            />
            <Route
              path="/instructions"
              element={
                <Instructions />
              }
            />
            <Route
              path="/admin"
              element={
                <AdminPanel />
              }
            />
          </Routes>
        </Router>
      </LanguageProvider>
    </ConfigProvider>
  );
}


export default App;


