import React from "react";
import axios from 'axios';


 
export const VoucherRequest: React.FC = () => {

    const login = async () =>  {
        console.log('API request - POST');
        axios
          .post('/api/createVoucherTest1')
          .then(res => {
            console.log(res);
            console.log(res.data);
          })
          console.log('after Axios');
        }

        return (
            <>
                <div>
                    <div>
                        <h2>Time now: {new Date().toISOString()}</h2>    
                        <button type="submit" onClick={login}>
                            24h Direkter Zugang &nbsp;&nbsp;&nbsp;&nbsp; -&gt;
                        </button>
                    </div>
                </div>
            </>
        )
}
    
export default VoucherRequest;