import { useLanguage } from '../../contexts/LanguageContext';
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import AdminPanel from "./AdminPanel";
import { Button, Modal, Input, Table, TableColumnsType } from 'antd';
import DeclineModal from './modals/DeclineModal'
import { findByTestId } from '@testing-library/react';
import "./AdminPanel.css";



const RequestedVocherTable: React.FC = () => {
    const [data, setData] = useState([]);


    const { language } = useLanguage();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async (rowData: any) => {
        setIsModalOpen(false);
        const declined = await axios
            .post(`api/declineVoucher`, { rowData });
        window.location.reload();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const { TextArea } = Input;



    type Data = {
        Voucher_ID: number,
        GUID: string,
        Firstname: string,
        Lastname: string,
        Email: string,
        ContactPerson: string,
        Password: string,
        RequestTime: Date,
        Note: number,
        Custom_Voucher: boolean,
        Duration: number,
        Multiuse: boolean,
        Devices: number,
        Reason: string,
        State: string,
    }

    const columns: TableColumnsType<Data> = useMemo(
        () => [
            {
                title: 'Note', dataIndex: 'Note', sorter: (a, b) => a.Note - b.Note,
            },
            {
                title: 'Angefragt um', dataIndex: 'RequestTime',
            },
            {
                title: 'Vorname', dataIndex: 'Firstname',
            },
            {
                title: 'Nachname', dataIndex: 'Lastname',
            },
            {
                title: 'E-Mail', dataIndex: 'Email',
            },
            {
                title: 'Kontaktperson', dataIndex: 'ContactPerson',
            },
            {
                title: 'Passwort', dataIndex: 'Password',
            },
            {
                title: 'Custom Voucher', dataIndex: 'Custom_Voucher',
            },
            {
                title: 'Gültigkeitsdauer', dataIndex: 'Duration',
            },
            {
                title: 'Mehrfachnutzung', dataIndex: 'Multiuse',
            },
            {
                title: 'Anzahl Geräte', dataIndex: 'Devices',
            },
            {
                title: 'Begründung', dataIndex: 'Reason',
            },
            {
                title: 'Status', dataIndex: 'State',
                render: (text, rowData) => {
                    return (
                        <div style={{ width: '6rem' }}>
                            <span >
                                <button style={{ background: '#4CAF50', color: "white", padding: " 0.3rem 0.5rem", width: "5rem", margin: '0 3px 3px 0' }} onClick={() => accept(rowData)} > Annehmen </button>
                                {/* <a href="https://www.google.at/" style={{ color: "white", height: "1rem" }}>Annehmen           </a> */}
                            </span>
                            <span >
                                <button style={{ background: "#E57373", color: "white", padding: "0.3rem 0.5rem", width: "5rem" }} onClick={() => setIsModalOpen(true)} > Ablehnen </button>
                                {/* <a href="http://www.yahoo.at/" style={{ color: "white", height: "1rem" }}>             Ablehnen</a> */}
                            </span>
                        </div>
                    );
                },
            },
        ]satisfies TableColumnsType<Data>,
        []
    );

    const accept = async (rowData: Data) => {
        console.log(rowData);


        const accepted = await axios
            .post(`api/acceptVoucher`, { rowData });

        console.log('ACC', accepted);
        const acceptedData = accepted.data
        const recipient = rowData.Email;
        console.log(rowData.Email);


        await axios
            .post(`api/sendEmail`, { recipient, accepted, language })


        window.location.reload();
    }

    const decline = (rowData: Data) => {
        console.log(rowData);

        // DeclineModal(rowData)
        showModal();
        setIsModalOpen(true);
        // window.location.reload();
    }






    // const data = React.useMemo(() => getData(), []);

    // console.log(data);





    useEffect(() => {
        (async () => {
            const res = await getData();
            setData(res)
        })();
    }, []);


    // const tableInstance = useTable({ columns, data }, useSortBy,);

    // const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     rows,
    //     prepareRow,
    // } = tableInstance;

    return (
        <>
            <Table<Data> columns={columns} dataSource={data} />
            <Modal
                title="Du bist im Begriff einen Voucher anzunehmen"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Input.TextArea rows={5}>help</Input.TextArea>
            </Modal>
        </>)
    // return (
    //     <>
    //         <table {...getTableProps}>
    //             <thead>
    //                 {headerGroups.map(headerGroup => (
    //                     <tr>
    //                         {headerGroup.headers.map((column: any) => (
    //                             <th {...column.getHeaderProps(column.getSortByToggleProps())}>
    //                                 {column.render('Header')}
    //                                 <span>
    //                                     {column.isSorted ? column.isSortedDesc ? " 🔽" : " 🔼" : " ◀️"}
    //                                 </span>
    //                             </th>

    //                         ))}
    //                     </tr>
    //                 ))}
    //             </thead>
    //             <tbody {...getTableBodyProps()}>
    //                 {rows.map((row) => {
    //                     prepareRow(row)
    //                     return (
    //                         <tr {...row.getRowProps()}>
    //                             {row.cells.map((cell) => {
    //                                 return (
    //                                     <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
    //                                 )
    //                             })}
    //                         </tr>
    //                     )
    //                 })}

    //             </tbody>
    //         </table>


    //         <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
    //             <TextArea rows={4} placeholder="maxLength is 1000" maxLength={1000} />

    //         </Modal>
    //     </>
    // );
}


const getData = async () => {
    const result = await axios("api/getPendingData");
    console.log('resuöt::: ', result.data);

    return result.data;
}
export default RequestedVocherTable;
